.list-of-cities {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .is-capital {
    font-weight: 900;
  }

  .is-city {
    font-weight: 500;
  }

  .is-town {
    font-weight: 400;
    font-style: italic;
  }
}