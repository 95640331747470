.app-loading-screen {
  position: absolute;
  z-index: 10000;
  top: 0;
  left: 0;
  background-image: url("../../public/images/map-bg.jpg");
  background-size: cover;
  background-position: 50% 50%;
  mask: url("../../public/images/map-sprite.webp");
  mask-size: 3000% 100%;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  color: #5d5037;
}

.app-loading-screen.loading-done {
  animation: mask-play 1s steps(29) forwards;
}

.app-loading-screen--windrose-container {
  align-self: center;
  text-align: center;
  width: 300px;
  height: 300px;
}

.app-loading-screen--windrose {
  max-width: 100%;
  max-height: 100%;
  border-radius: 100%;
  transform: scale(1);
  transition: transform .5s ease-in-out;
}

.app-loading-screen--label {
  transition: opacity .2s ease-in-out;
  opacity: 1;
}

.loading-done .app-loading-screen--windrose {
  transform: scale(.2);
}

.loading-done .app-loading-screen--label {
  opacity: 0;
}

.app-loading-screen--windrose .rose path:nth-child(2) {
  fill: rgba(255, 255, 255, .9);
}

.app-loading-screen--windrose .rose {
  display: none;
}

.app-loading-screen--windrose-rose {
  animation: rotate 8s infinite reverse;
  animation-timing-function: linear;
  transform-origin: center;
  fill: #5d5037;
}

.app-loading-screen--windrose-rose-marker {
  animation: rotate 4s infinite;
  animation-timing-function: linear;
  transform-origin: center;
  fill: #5d5037;
}

.app-loading-screen--windrose-rose-bg {
  animation: rotate 8s infinite;
  animation-timing-function: linear;
  transform-origin: center;
  stroke: #5d5037;
}

.app-loading-screen--windrose-rose-bg path {
  stroke: #5d5037;
}

.app-loading-screen--windrose-rose-bg-ripple {
  animation: ripple 1.5s infinite;
  animation-timing-function: ease;
  transform-origin: center;
}

.app-loading-screen--windrose-rose-bg-ripple path {
  stroke: #5d5037;
}

@keyframes ripple {
  from {
    transform: scale(1);
    opacity: .8;
  }
  to {
    transform: scale(1.2);
    opacity: 0;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(280deg);
  }
}

@keyframes radial {
  from {
    transform: scale(5);
  }
  to {
    transform: scale(0);
  }
}

@keyframes mask-play {
  from {
    -webkit-mask-position: 0 0;
    mask-position: 0 0;
  }
  to {
    -webkit-mask-position: 100% 0;
    mask-position: 100% 0;
  }
}