.sidebar {
  --sidebar-bg: url("../../public/images/sidebar-bg.jpg");
  
  box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.5);
  background-image: var(--sidebar-bg);
  position: absolute;
  width: 420px;
  left: -420px;
  font-size: 0.875rem;
  z-index: 50;
  border: 0;
  border-right: 2px solid #422d17;
  height: 100%;
  transition: all 0.25s ease-in-out;

  a {
    --color: 0 160 249;
    position: relative;
    text-decoration: none;

    &::after {
      content: "";
      background: rgb(var(--color) / .5);
      position: absolute;
      left: clamp(5px, 15%, 10px);
      bottom: 2%;
      width: calc(100% - 8px);
      height: 6px;
      z-index: -1;
      transition: 0.35s cubic-bezier(0.25, 0.1, 0, 2.05);
    }


    &[href*="city"],
    &[href*="region"],
    &[href*="poi"] {
      --color: 59 194 57;
      text-decoration: none;
    }
  }
}

@media (max-width: 500px) {
  .sidebar {
    max-width: 100vw;
    position: fixed;
    bottom: 0;
  }
  .sidebar--expanded {
    bottom: auto;
    height: 100%;
  }
  .sidebar--expanded .sidebar-img-top {
    display: block;
  }
}

.sidebar-body {
  padding: 1rem 1.25rem 0 1.25rem;
}

.sidebar-body .sidebar-section {
  border-top: 1px solid #dee2e6;
  padding: 1rem 1.25rem 0 1.25rem;
}

.sidebar-img-top {
  height: 350px;
  align-items: center;
  position: relative;
  flex-shrink: 0;
  margin: 0;
  background-image: url("../../public/images/tiles/map/5/16/1.webp");
  mask: url("../../public/images/sidebar-bg-mask-bottom.png");
  mask-position: bottom;
  mask-repeat: no-repeat;
}

.sidebar-img-top--empty {
  height: 100px;
}

.sidebar-header {
  position: relative;
  filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.85));
}

.sidebar-header::before {
  content: '';
  position: absolute;
  top: 0;
  z-index: 99;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-image:
          linear-gradient(
                          rgba(0, 0, 0, .1),
                          rgba(0, 0, 0, .1),
          ),
          var(--sidebar-bg);
  filter: contrast(1.5) brightness(0.75);
  mask-image: url("../../public/images/sidebar-bg-mask.png");
  mask-size: contain;
}

.sidebar-title {
  @apply mx-2 text-center relative z-[100] flex items-center justify-center gap-1 h-24;
}

.sidebar-title h2 {
  @apply text-2xl;
}

.sidebar.sidebar--open {
  left: 0;
}

.sidebar__content {
  height: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}

.sidebar__content-main {
  font-weight: 300;
  font-size: 0.95rem;
}

.sidebar__content p:not(:first-of-type) {
  @apply mt-2;
}

.sidebar__content-main h3 {
  font-weight: 700;
  font-size: 1.25rem;
  margin: 1.75rem auto 1rem auto;
}

.sidebar__content-main > :first-child:is(h3) {
  margin: 0.5rem auto 1rem auto;
}

@media (max-width: 500px) {
  .sidebar__content-main > :first-child:is(h3) {
    margin-top: 1rem;
  }
}

.sidebar__content [class^="accordion"] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}

.sidebar__floating_controls {
  position: sticky;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  padding: 0.5rem 0.5rem;
  justify-content: flex-end;
}

.sidebar__btn-close {
  cursor: pointer;
  color: #fff;
  font-size: 1.25rem;
  border: none;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 100%;
  width: 32px;
  height: 32px;
  outline: none;
  transition: all 0.4s;
}

.sidebar__btn-close:hover {
  background-color: rgba(255, 255, 255, 0.55);
  color: #666;
}

.sidebar__handle {
  position: absolute;
  z-index: 0;
  top: calc(50% - 24px);
  left: 420px;
}

.sidebar__handle[aria-expanded=false] .sidebar__handle__button {
  width: 60px;
  height: 80px;
}

.sidebar__handle__button {
  transition-property: width, height;
  transition-duration: 0.25s;
  border: 0;
  padding: 0;
  width: 50px;
  height: 60px;
  cursor: pointer;
  background-image: url("../../public/images/sidebar-close.png");
  background-color: transparent;
  background-size: cover;
  background-position: center right;
  background-repeat: no-repeat;
}
