.loader-container {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  color: #fff;

  @apply backdrop-blur-xl;
}

.loading-indicator {
  stroke: currentColor;
}